<template>
  <div
    class="plat-adv-container"
    v-lazy:background-image="{src: require('../../../assets/images/bg4.png')}"
  >
    <div class="page-outter">
      <div align="center">
        <img
          class="plat-adv-title"
          v-lazy="require('../../../assets/images/title6.png')"
        />
        <div class="plat-adv-inner">
          <ul>
            <li
              v-for="(item,index) in tds"
              :key="index"
            >
              <div class="plat-adv-dd">
                <div class="plat-adv-item">
                  <img v-lazy="item.icon" />
                </div>
                <span class="plat-adv-item-title">
                  {{item.title}}
                </span>
                <span
                  class="plat-adv-item-s1"
                  v-html="item.name"
                ></span>
              </div>
            </li>
          </ul>
          <div style="clear: both"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PlatformAdvantage",
  data() {
    return {
      tds: [
        {
          icon: require("../../../assets/images/app-icon7.png"),
          title: "安全可靠",
          name: `平台信息机密技术通过<br>专业机构认真，可防止<br>信息被窃取篡改`,
        },
        {
          icon: require("../../../assets/images/app-icon8.png"),
          title: "真实可查",
          name: `平台与信用管理平台<br>政府企业信息管理系统等对接<br>及时核查企业信息<br>上传资料等，确保文件真实性`,
        },
        {
          icon: require("../../../assets/images/app-icon9.png"),
          title: "便捷开放",
          name: `一次上传，可永久使用<br>平台可开放接口<br>实现与企业内部系统对接<br>随时获取上传信息`,
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.plat-adv-container {
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  width: 100%;
  padding-bottom: 2.4rem;
  background-repeat: no-repeat;
  background-size: cover;
}
.plat-adv-title {
  width: 7rem;
  margin-top: 2.4rem;
}
.plat-adv-inner {
  display: flex;
  justify-content: center;
  ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
    margin-left: -3rem;
    li {
      float: left;
      margin-left: 3rem;
      margin-top: 1.8rem;
    }
  }
}
.plat-adv-dd {
  width: 5.8rem;
}
.plat-adv-item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.4rem;
  height: 2.4rem;
  border: 1px solid #dcdadd;
  border-radius: 2.4rem;
  img {
    width: 1.6rem;
  }
}
.plat-adv-item-title {
  display: block;
  font-size: 0.4rem;
  color: #fff;
  margin-top: 0.45rem;
}
.plat-adv-item-s1 {
  display: block;
  font-size: 0.36rem;
  color: #dcdadd;
  line-height: 1.7;
  margin-top: 0.26rem;
}
</style>